.test {
  max-width: 1440px;
  width:90%;
  margin: auto;
  line-height: 1.7em;
}

.test h1{
  font-size: 40px;
  font-family: 'Oswald';
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 25px;
}
.test p{
  text-align: left;
  font-size: 16px;
  font-family: 'Noto Sans';
  font-weight: 400;
  margin-bottom: 40px;
}