.home-about {
  max-width: 1440px !important;
  width:90% !important;
  display: flex;
  align-items: stretch;
  border-radius: 8px;
  margin-top: 20px;
  margin: auto;
  padding-top:60px;
  padding-bottom:60px;
  margin-bottom:60px;
  background-color: transparent;
  background-size: cover;
}

.home-about-text {
  padding-top: 30px;
  padding-right: 20px;
  flex: 1;
  text-align: left;
}

.home-about-image {
  flex: 1;
  height: 500px;
  background-image: url('../components/About_Home.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  
}

.home-about-content {
  position: relative;
}

.home-about h2 {
  font-size: 40px;
  margin-bottom: 10px;
  font-family: 'Oswald';
  color:#0f1525;
  font-weight: 500;
}

.home-about p {
  font-size: 16px;
  line-height: 1.6;
  font-family: 'Noto sans';
  text-align: left;
  color:#333333;
}



.projektet {
  font-size:45px;
  font-family: 'Oswald';
  text-transform: uppercase;
  max-width: 1440px;
  width:90%;
  margin: auto;
  font-weight: 700;
  color:#0f1525;
  margin-bottom: 80px;
  text-align: center;
}

hr {
  width:40px;
  height: 3px;
}

.that {
  border-bottom:4px solid #ee8d01;
  max-width:40px;
  padding-top: 15px;
  margin-bottom: 15px;
}

.btn1 {
  padding: 13px 35px;
  color:#0f1525;
  font-family: 'Noto sans';
  font-size:16px;
  background-color: #ee8d01;
  margin-bottom: 20px;
  border-radius:8px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  border-style: none;
}

.btn1:hover {
  background-color: #0f1525;
  color: white;
  transform: scale(1.05);
}

/* Media Queries for Tablets */
@media (max-width: 980px) {
  .home-about {
    padding-top:30px;
    padding-bottom:30px;
  }
  
  .home-about h2 {
    font-size: 32px;
  }
  
  .home-about {
    display: block;
  }

  .home-about p {
    font-size: 18px;
  }

  .projektet {
    font-size:30px;
    font-family: 'Oswald';
    text-transform: uppercase;
    max-width: 1440px;
    width:90%;
    margin: auto;
    font-weight: 700;
    color:#0f1525;
    padding-bottom: 20px;
  }
}

/* Media Queries for Mobile Devices */
@media (max-width: 480px) {
  .home-about h2 {
    font-size: 24px;
  }

  .home-about-image {
    height: 300px;
    background-image: url('../components/About_Home.png');
    background-size:contain;
    background-position: center;
    border-radius: 8px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .home-about p {
    font-size: 14px;
  }

  .projektet {
    font-size:28px;
    font-family: 'Oswald';
    text-transform: uppercase;
    max-width: 1440px;
    width:90%;
    margin: auto;
    padding-bottom: 20px;
    font-weight: 700;
    color:#0f1525;
  }
}
