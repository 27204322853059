.about-outer-container {
  width: 100%;
  padding: 20px 0;
}

.about-container {
  display: flex;
  gap: 25px;
  max-width: 1440px;
  width: 90%;
  margin: auto;
  padding: 20px;
  background-color: transparent;
  border-radius: 15px;
}

.about-header {
  max-width: 1440px;
  width: 90%;
  margin: auto;
}

.about-text p {
  font-size: 16px;
  font-family: 'Noto Sans';
  color: #333;
  line-height: 1.7em;
}

.about-hero {
  flex: 1;
  text-align: center;
}

.about-image {
  max-width: 100%;
  height: auto;
  border-radius: 15px;
  box-shadow: 1px -1px 27px -9px rgba(148, 148, 148, 1);
}

.about-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
}

.about-text {
  margin-bottom: 20px;
}

.about-heading {
  font-size: 40px;
  color: #0f1525;
  font-family: 'Oswald';
  font-weight: 600;
  text-transform: uppercase;
  padding-left: 15px;
  padding-top: 25px;
  padding-bottom: 25px;
}

.about-icons {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.about-icon {
  text-align: center;
  flex: 1;
  min-width: 150px;
}

.about-page-icon {
  font-size: 50px;
  margin-bottom: 10px;
  color: inherit;
}

.icon-title {
  font-size: 20px;
  color: #0f1525;
  font-weight: 600;
  font-family: 'Oswald';
}

.eco-friendly .about-page-icon {
  color: #4caf50; /* Green */
}

.solar-energy .about-page-icon {
  color: #ffeb3b; /* Yellow */
}

.energy-saving .about-page-icon {
  color: #ff5722; /* Orange */
}

.why-choose-us {
  padding: 40px 20px;
  border-radius: 15px;
  margin: 20px auto;
  max-width: 1440px;
  width: 90%;
}

.why-choose-heading {
  font-size: 40px;
  color: #0f1525;
  font-family: 'Oswald';
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 50px;
}

.why-choose-content {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: -150px;
  
}

.why-choose-item {
  display: flex;
  gap:15px;
  background-color: #ffffff;
  text-align: left;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 50px;
  padding-top: 50px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1;
  min-width: 250px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border:2px solid #ddd;
 
}
.contact-button{
  padding: 13px 35px;
  color:#0f1525;
  font-family: 'Noto sans';
  font-size:16px;
  background-color: #ee8d01;
  margin-bottom: 20px;
  border-radius:8px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  border-style: none;
  margin-left:20px;
  margin-top:35px;
}
.contact-button:hover {
  background-color: white;
  color: #ee8d01;
  transform: scale(1.05);
  border: 3px solid #ed8d02;
}

.why-choose-item:hover {
  transform: translateY(-10px);
  -webkit-box-shadow: 0px 0px 44px -16px rgba(237,141,2,1);
-moz-box-shadow: 0px 0px 44px -16px rgba(237,141,2,1);
box-shadow: 0px 0px 44px -16px rgba(237,141,2,1);
border:2px solid transparent;
}
.why-choose-title{
  text-align: left;

}
.why-choose-text p{
  font-family: 'Noto Sans';
  text-align: left;
}
.why-choose-text h3{
  font-family: 'Oswald';
  font-weight: 700;
  color:#0f1525;
  text-align: left;
}


.number {
  font-size: 90px;
  color: #ed8d02;
  font-weight: bold;
  font-family: 'Oswald';
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 50%;
  width: 80px;
  height: 80px;
}

.why-choose-title {
  font-size: 24px;
  color: #101427;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
}

.why-choose-text {
  font-size: 16px;
  color: #333;
  text-align: center;
  line-height: 1.5;
}
.about-3{
  max-width: 1440px;
  width: 90%;
  margin: auto;
  padding-top:200px;
  padding-bottom: 200px;
  background-image: url('../components/Solar.png');
  background-position-x: bottoms;
  background-size: cover;
  background-repeat: no-repeat;
  
  }
  .about-3 h1{
    color:white;
    width: 95%;
    margin: auto;
    font-family: 'Oswald';
    font-size: 40px;
    }
    .about-3 p{
      color:white;
      width: 97%;
      margin: auto;
      padding-top: 20px;
      font-size: 16px;
      font-family: 'Noto Sans';
    }
  

@media (max-width: 980px) {
  .about-container {
    flex-direction: column;
    padding: 0px;
  }

  .about-content {
    padding-left: 0;
    text-align: left;
  }

  .about-icons {
    align-items: center;
  }
  .number {
    font-size: 60px;
    color: #ed8d02;
    font-weight: bold;
    font-family: 'Oswald';
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border-radius: 50%;
    width: 80px;
    height: 80px;
  }
  .about-3 h1{
    color:white;
    width: 95%;
    margin: auto;
    font-family: 'Oswald';
    font-size: 40px;
    }
}

@media (max-width: 500px) {
  .about-container {
    flex-direction: column;
  }

  .about-content {
    padding-left: 0;
    text-align: left;
  }

  .about-icons {
    flex-direction: column;
  }
  .number {
    font-size: 40px;
    color: #ed8d02;
    font-weight: bold;
    font-family: 'Oswald';
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border-radius: 50%;
    width: 80px;
    height: 80px;
  }
  .about-3 h1{
    color:white;
    width: 95%;
    margin: auto;
    font-family: 'Oswald';
    font-size: 30px;
    }
}
