.carousel-item {
  position: relative;
  min-height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.carousel-item img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.carousel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.204);
}

.carousel-caption {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-18%);
  text-align: left;
  z-index: 3;
  padding: 0 20px;
}

.carousel-caption h3 {
  font-size: 50px;
  font-family: 'Oswald';
  text-align: left;
  margin: 0;
  color:#ed8d02;
  font-weight: 700;
  text-shadow: 5px 5px 5px #00000041;
  
}
.carousel-caption button {
   padding: 15px 35px;
  color:white;
  font-family: 'Noto sans';
  text-shadow: 3px 3px 3px #00000041;
  font-weight: 700;
  border:3px solid #ee8d01 ;
  font-size:16px;
  background-color: #ee8d01!important;
  backdrop-filter: blur(5px);
  margin-bottom: 20px;
  border-radius:50px;
  transition: background-color 0.3s ease, transform 0.3s ease !important;
}

.carousel-caption button:hover {
  background-color:#0f1525 !important;
  color: white;
  transform: scale(1.05);
  padding: 15px 35px;
  border:3px solid #0f1525 ;
}
button.btn.btn-primary {
  display: flow-root;
  margin-top: 25px;
}

.carousel-indicators {
  position: absolute;
  z-index: 4;
  display: flex;
  margin-right: 15%;
  margin-bottom: 1rem;
}

.carousel-indicators li {
  background-color: white;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 15px;
}

.carousel-indicators .active {
  background-color: #ee8d01;
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 50%;
  width: auto;
  height: auto;
  padding: 0 15px;
  margin-top: -22px;
  color: white !important;
  font-size: 30px;
  text-align: center;
  filter: invert(1) !important;
  z-index: 5;
  background-color: transparent;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

@media (max-width: 980px) {
  .carousel {
    position: relative;
    min-height: 600px;
    overflow: hidden;
  }
  .carousel-item {
    min-height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
}

@media (max-width: 500px) {
  .carousel {
    position: relative;
    min-height: 370px;
    overflow: hidden;
  }
  .carousel-item {
    min-height: 370px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .carousel-caption h3 {
    font-size: 30px;
    font-family: 'Oswald';
    text-align: left;
    margin: 0;
  }
  .carousel-caption {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-70%);
    text-align: left;
    z-index: 3;
    padding: 0 20px;
  }
  .carousel-caption button {
    padding: 15px 25px;
  }
}