/* Ensure the container is centered */
.MDBContainer, .MDBRow, .MDBCol {
  padding: 0;
  margin: 0;
}

/* Gallery item styling */
.gallery-item {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 75%; 
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px; 
  border-radius: 8px; 
  overflow: hidden;
}


.gallery-item img {
  position: absolute; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit:fill !important; 
  transition: transform 0.3s ease-in-out;
  border-radius: 8px; 
}


.gallery-item:hover img {
  transform: scale(1.1);
}


.gallery-item .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; 
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  z-index: 2;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
  border-radius: 8px; 
  box-sizing: border-box; 
}


.gallery-item:hover .overlay {
  opacity: 1;
}


.gallery-item .overlay p {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  font-family: 'Oswald';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  pointer-events: none;
}
