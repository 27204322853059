.partners-container {
  background-color: rgba(232, 232, 232, 0.574);
  padding: 50px 0;
  margin-top: 50px;
  overflow: hidden;
}

.partners-section {
  text-align: center;
  padding: 20px;
  background-color: transparent;
  max-width: 1440px;
  width: 90;
  margin: auto;
  position: relative;
}


.partners-list {
  display: flex;
  flex-wrap: nowrap;
  width: 200%; 
  animation: scroll 30s linear infinite;
}

.partners-item {
  background-color: #fff;
  margin-left:20px;
  border-radius: 8px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
  align-content: center;
  padding: 15px;
  flex: 0 0 200px; 
}

.partners-item img {
  max-width: 120px;
  width: 100%;
  height: auto;
  display: block;
  margin: auto;
}
.projektet {
  font-size:45px;
  font-family: 'Oswald';
  text-transform: uppercase;
  max-width: 1440px;
  width:90%;
  margin: auto;
  font-weight: 700;
  color:#0f1525;
  margin-bottom: 80px;
  text-align: center;
}

.partners-item p {
  font-size: 18px;
  color: #0f1525;
  font-family: 'Oswald';
  padding-top: 20px;
  font-weight: 500;
}

.partners-item:hover {
  transform: scale(1.1);
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%); /* Move by 50% of the container width */
  }
}

@media (max-width: 1100px) {
  .partners-section h2 {
    font-size: 40px;
  }
}

@media (max-width: 600px) {
  .partners-section h2 {
    font-size: 30px;
  }
}
