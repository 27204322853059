@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

body {
  margin: 0;
  padding: 0;
}
.navbar-wrapper {
  background-color:azure !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}


.navbar-container {
  font-family: 'Oswald', sans-serif;
  font-weight: 500;
  width: 90%;
  max-width: 1440px;
  margin: 0 auto;
  height: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  padding: 0 20px;
  letter-spacing: 1px;
  box-sizing: border-box;
  background-color: transparent; /* Adjust if needed */
}

.nav-menu {
  display: flex;
  align-items: center;
}

.nav-item {
  font-size: 16px;
  color: #333333;
  padding: 15px 20px;
  text-decoration: none;
}

.nav-logo img {
  max-width: 220px;
}

.nav-item:hover {
  color: #333333;
}

.nav-item:active,
.nav-item.active {
  color: #333333;
  border-radius: 2px;
  background-color: #4e5fb23d;
  color: #0f1525;
  -webkit-box-shadow: 10px 10px 36px -21px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 36px -21px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 36px -21px rgba(0, 0, 0, 0.75);
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  transform-origin: center;
  width: 27px;
  height: 27px;
  border-radius: 50px;
}

.bar {
  width: 100%;
  height: 3px;
  background-color: #333333;
  border: 2px solid #333333;
  margin: 3px 0;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.open .bar:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.open .bar:nth-child(2) {
  opacity: 0;
}

.open .bar:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

.mobile-menu {
  display: none;
  flex-direction: column;
  background-color: #efe9e77d;
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  z-index: 99;
}

.mobile-menu.open {
  left: 0;
  opacity: 1;
  animation: slideInRight 0.5s forwards;
}

@keyframes slideInRight {
  from {
    left: -100%;
  }
  to {
    left: 0;
  }
}

@keyframes slideOutRight {
  from {
    left: 0;
  }
  to {
    left: -100%;
  }
}

.mobile-menu-header {
  display: flex;
  justify-content: flex-end;
  background-color: #EFE9E7;
  padding: 20px;
  width: 100%;
  margin-top: 30px;
}

.close-icon {
  width: 35px;
  height: 25px;
  cursor: pointer;
  background-color: transparent;
  color: black;
}

.close-icon:hover {
  color: #ED8E02;
}

.mobile-nav-item {
  color: #333333;
  font-size: 20px;
  text-transform: uppercase;
  padding: 15px 20px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.mobile-nav-item:hover {
  background-color: #0f1525;
  font-weight: 500;
}

.mobile-nav-item:active,
.mobile-nav-item.active {
  background-color: #0f1525;
  color: white;
}

@media (max-width: 980px) {
  .nav-menu {
    display: none;
  }

  .hamburger {
    display: flex;
  }

  .mobile-menu {
    display: flex;
  }

  .mobile-menu-header {
    margin-top: 10px;
  }

  .nav-logo img {
    max-width: 200px;
  }

  .navbar-container {
    height: 80px;
    padding: 0 16px;
  }
}
