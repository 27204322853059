.card-section{
  position: relative;
  background-image: url("card-background.png");
  background-size: contain;
  background-position:center;
  margin-bottom: 60px;
}


.cards-container {
  max-width: 1440px !important;
  margin: auto !important;
  width: 90% !important;
  
}

.card-link {
  margin: 0;
  text-decoration: none;
}

.card {
  background-size: cover !important;
  background-position: center !important;
  height: 330px !important;
  border-radius: 5px !important;
  position: relative !important;
  overflow: hidden !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  transition: transform 0.3s ease !important;
  margin-bottom: 20px !important;
  --bs-card-border-width: 0 !important;
  --bs-card-border-color: transparent !important;
  border: none !important;
}

.card:hover {
  transform: scale(1.05) translateY(-5px) !important;
  border:2px solid #101427 !important;
}

.card-content {
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  background: rgb(227, 229, 240) !important; 
  padding: 16px !important;
  border-radius: 0 !important;
  color: #101427 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  opacity: 1;
  transition: opacity 0.5s ease !important;
}

.card:hover .card-content {
  background:#101427 !important; 
  opacity: 1;
}

.card-content h2 {
  font-family: 'Oswald' !important;
  font-size: 20px !important;
  margin: 0 !important;
  transition: color 0.3s ease !important;
}

.card:hover .card-content h2 {
  color: #fff !important; 
}

.card-content p {
  font-size: 14px !important;
  color: #fff !important; 
  transition: opacity 0.3s ease !important;
}

.card:hover .card-content p {
  opacity: 0; 
}

@media (max-width: 768px) {
  .card {
    height: 350px !important;
  }
}

@media (max-width: 576px) {
  .card {
    height: 320px !important;
  }
}
