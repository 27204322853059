.contact-us-section {
  position: relative;
  background-image: url("Contac-us-backgournd.png");
  background-size: contain;
  background-position:left;
  margin-top: 20px;
  margin-bottom: 0px;
  padding-bottom: 60px;
}

.contact-us-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.268); 
  z-index: 1; 
}

.contact-us-section > * {
  position: relative;
  z-index: 2;
}




.contact-us-container {
  max-width: 1440px;
  width: 90%;
  margin: 50px auto 0px auto;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.contact-info {
  flex: 1;
  padding: 20px;
  border-radius: 8px;
  flex-basis: 50%;
  background-color: #ffce6b;
  margin: auto;
  padding: 50px;
  -webkit-box-shadow: 1px 1px 39px -12px rgba(0, 0, 0, 0.356);
  -moz-box-shadow: 1px 1px 39px -12px rgba(0, 0, 0, 0.356);
  box-shadow: 1px 1px 39px -12px rgba(0, 0, 0, 0.356);
}

.contact-info h2 {
  font-size: 24px;
  font-family: 'Oswald';
  text-transform:capitalize;
  color: #0f1525;
  margin-bottom: 40px;
}

.info-item {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 20px;
  border-bottom: 2px solid #0f1525;

  padding-bottom: 40px;
}

.info-item .icon {
  font-size: 20px;
  color: #0f1525;
  margin-right: 10px;
}

.info-item p,
.info-item a {
  margin: 0;
  font-size: 18px;
  color:#0f1525; 
  font-family: 'Oswald';
  letter-spacing: 1px;
  text-decoration: none; 
}

.info-item a:hover {
  text-decoration: underline;
}

.contact-form {
  flex: 2;
  padding: 20px;
  border-radius: 8px;
  flex-basis: 59%;
}

.contact-form h2 {
  font-size: 24px;
  font-family: 'Oswald';
  text-transform: uppercase;
  color: #0f1525;
  margin-bottom: 20px;
}

form {
  display: block;
  gap: 15px;
  max-width: 500px;
  margin: auto;
}

.form-group {
  display: flex;
  flex-direction: column;
}
.contact-form h2{
  max-width: 500px;
  margin: auto;
  text-align:left;
  padding-bottom: 30px;
  text-transform: uppercase;
}

.form-group label {
  font-size: 16px;
  font-family: 'Noto Sans';
  padding-top: 10px;
  font-weight: 600;
  color: #0f1525;
  margin-bottom: 5px;
}

.form-group input {
  padding: 10px;
  font-weight: 600;
  font-family: 'Noto Snas';
  font-size: 16px;
  border: 4px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-group textarea {
  grid-column: 1 / -1; 
  width: 100%;
  resize: vertical;
  min-height: 100px;
  border: 4px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.submit-btn {
  grid-column: 1 / -1; 
  font-family: 'Noto Sans';
  font-weight:600;
  margin-top: 30px;
  padding: 10px 50px !important;
  font-size: 16px;
  background-color: #0f1525;
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #ed8d02;
}

button.submit-btn {
  margin-top: 30px;
  padding: 13px 50px;
}

.success-message,
.error-message {
  font-size: 16px;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 4px;
}

.success-message {
  color: #4caf50;
  background-color: #dff0d8;
}

.error-message {
  color: #f44336;
  background-color: #f2dede;
}

@media(max-width:980px){
  .contact-us-container {
    display: block;
    max-width: 1440px;
    width: 90%;
    margin: 80px auto;
    padding: 20px;
   
  }
}

@media(max-width:500px){
  .contact-us-container {
    display: block;
    max-width: 1440px;
    width: 90%;
    margin: 80px auto;
    padding: 0px;
   
  }
  .contact-info {
    flex: 1;
    padding: 20px;
    border-radius: 8px;
    flex-basis: 50%;
    background-color: #ffce6b;
    margin: auto;
    padding:20px;
    -webkit-box-shadow: 1px 1px 39px -12px rgba(0, 0, 0, 0.356);
    -moz-box-shadow: 1px 1px 39px -12px rgba(0, 0, 0, 0.356);
    box-shadow: 1px 1px 39px -12px rgba(0, 0, 0, 0.356);
  }
}