body,
h1,
h2,
h3,
p,
ul,
ol {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Oswald';
  background-color: #f9f9f9;
  color: #333;
}

.detail-page {
  max-width: 1440px !important;
  width:90% !important;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Noto sans';

}

.title {
  font-size: 28px;
  margin-bottom: 15px;
  font-family: 'Oswald' !important;
  text-transform: uppercase;
}
.main-image {
  max-width: 800px !important;
  width: 100%;
  margin-bottom: 20px;
}

.meta {
  margin-bottom: 20px;
  font-family: 'Oswald';
}

.author {
  font-size: 14px;
  color: #666;
  display: none;
}

.main-image {
  max-width: 1440px;
  width: 100%;
  margin-bottom: 20px;
}

.content {
  line-height: 1.8;
}

.content p {
  margin-bottom: 15px;
}

.content img {
  max-width: 100%;
  min-height: auto;
  margin-bottom: 20px;
}

.text-content {
  margin-bottom: 20px;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.gallery-item {
  position: relative;
  overflow: hidden;
}

.gallery-item img {
  object-fit: cover;
  width: 100%;
  height: 240px;
  cursor: pointer;
}

/* Media Queries */
@media (max-width: 992px) {
  .gallery {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 576px) {
  .gallery {
    grid-template-columns: repeat(1, 1fr);
  }
}
