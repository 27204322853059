
.services-section {
  background: transparent;
  text-align: center;
  max-width: 1440px;
  width: 90%;
  margin: auto;

}

.services-section h2 {
  font-size: 45px;
  text-align: center;
  margin-top:60px;
  margin-bottom: 80px;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  color: #0f1525;
  font-weight: 700;
}

.services-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  max-width: 1440px;
  width: 95%;
  margin: 30px auto 80px auto;
}

.service-item {
  max-width: 500px;
  padding: 40px 20px;
  background-color: #fff;
  border: 5px solid #D9D9D9;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.5s ease, transform 0.5s ease;
}

.service-item:hover {
  background-color: #ee8d01;
  transform: translateY(-10px);
  border: 5px solid #ee8d01;
}

.service-item:hover h3,
.service-item:hover p,
.service-item:hover svg {
  color: white;
  transition: color 0.3s ease;
}

.service-item h3 {
  font-size: 25px;
  margin: 10px 0;
  font-family: 'Oswald', sans-serif;
  color: #0f1525;
  text-align: center;
}

.service-item p {
  font-size: 16px;
  color: #333333;
  font-family: 'Noto Sans', sans-serif;
  text-align: left;
}


.icon-solar {
  color: #f39c12; 
}

.icon-battery {
  color: #3498db; 
}

.icon-wind {
  color: #2ecc71;
}

.icon-leaf {
  color: #27ae60; 
}

@media (max-width: 1100px) {
  .services-list {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
  .services-section h2 {
    font-size: 40px;
  }
}

@media (max-width: 600px) {
  .services-list {
    grid-template-columns: 1fr;
  }
  .services-section h2 {
    font-size: 30px;
  }
}
