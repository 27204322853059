.faq-container {
  max-width: 1440px;
  width: 90%;
  margin: 0 auto;
  padding: 20px;
  margin-bottom: 80px;
}

.faq-title {
  text-align: center;
  margin-bottom: 40px;
  font-size: 28px;
  font-family: 'Oswald';
  font-weight: bold;
  
}

.faq-content {
  display: flex;
  align-items: center;
}

.faq-image {
  flex: 1;
  margin-right: 40px;
  flex-basis: 25%;
}

.faq-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}
.faq-title{
  font-size:45px;
  font-family:'Oswald';
  text-transform: uppercase;
  font-weight: 600;
  color:#0f1525;
  padding-top: 40px;
  padding-bottom: 40px;
}
.faq-section {
  flex: 2;
  flex-basis: 70%;
}

.faq-item {
  border: 2px solid #0f1525;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.faq-question {
  padding: 15px;
  font-size: 18px;
  font-weight: 500;
  font-family: 'Oswald';
  letter-spacing: 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: #f9f9f9;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.faq-question:hover {
  background-color: #d5d9ed86;
}

.faq-icon {
  font-size: 24px;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  padding: 0 15px;
  font-size: 16px;
  transition: max-height 0.3s ease, padding 0.3s ease;
}

.faq-answer.show {
  max-height: 100%;
  padding: 15px;
}

@media(max-width:980px){
  .faq-content {
    display: block;
    margin: auto;
  }
  .faq-image img {
    display: block;
    margin: auto;
    padding-bottom: 40px;
    max-width: 400px;
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
}

@media(max-width:500px){
  .faq-content {
    display: block;
    margin: auto;
  }
  .faq-image img {
    display: block;
    margin: auto;
    padding-bottom: 30px;
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  .faq-title{
    font-size:30px;
    font-family:'Oswald';
    text-transform: uppercase;
    font-weight: 600;
    color:#0f1525;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .faq-question {
    padding: 15px;
    font-size: 15px;
    font-weight: 500;
    font-family: 'Oswald';
    letter-spacing: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background-color: #f9f9f9;
    border-radius: 8px;
    transition: background-color 0.3s ease;
  }
  
}

