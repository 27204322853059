

.staff-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 40px 20px;
  background-color: #f9f9f9;
  min-height: 100vh; 
}
.staff-section h2 {
  font-family: 'Oswald';
  font-weight: 700;
  font-size: 40px;
  text-transform: uppercase;
  margin-bottom: 80px;
  margin-top: 80px;
}

.staff-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 30px;
  justify-content: center; 
  max-width: 1200px;
  width: 100%;
}

.staff-card {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.staff-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.staff-card img {
  width: 100%;
  border-radius: 50%;
}

.staff-info {
  margin-top: 10px;
}

.staff-info h3 {
  margin: 0;
  font-size: 1.2em;
  color: #101427; 
}

.staff-info p {
  color: #ed8d02; 
  font-size: 1em;
}

.social-links {
  margin-top: 10px;
}

.social-links a {
  margin: 0 10px;
  color: #101427; 
  font-size: 1.5em;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: #ed8d02; 
}
