.footer {
  background-color: #0f1525;
  color: white;
  padding-top: 20px;
  text-align: center;
}

.footer-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  max-width: 1440px;
  width: 90%;
  margin: auto;
  padding: 30px 0;
}

.footer-logo {
  max-width: 250px;
  margin-bottom: 20px;
  filter: brightness(0) invert(1);
}

.footer-section {
  margin: 10px;
  background-color: transparent;
  padding: 20px;
  border-radius: 20px;
  text-align: left; /* Align text to the left */
}

.footer-section h4 {
  margin-bottom: 10px;
  font-size: 22px;
  font-family: 'Oswald';
  text-align: left;
  border-bottom: 1px solid white;
  padding-bottom: 10px;
}

.footer-section p,
.footer-section a {
  margin: 5px 0;
  font-size: 16px;
  font-family: 'Noto Sans';
  text-align: left;
  color: white;
  text-decoration: none;
}

.footer-section a:hover {
  color: white;
}

.footer-bottom {
  margin-top: 20px;
  padding-top: 15px;
  background-color: white;
}

.footer-bottom p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: 'Noto Sans';
  color: #0f1525;
}

.social-icons {
  display: flex;
  justify-content: flex-start;
  size: 20px;
}

.icon {
  font-size: 20px;
  margin: 0 5px;
}

@media(max-width: 980px) {
  .footer-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media(max-width: 500px) {
  .footer-container {
    grid-template-columns: 1fr;
  }
}
