.full-width-container {
  width: 100%;
  background-color: #f9f9f9; 
  padding: 20px 0; 
  margin-top:25px;
  margin-bottom: 60px;
}

.new-service-container {
  padding: 40px 20px;
  max-width: 1440px;
  width: 90%;
  margin: 0 auto;
}

.new-service-container h1 {
  font-size: 36px;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  margin-bottom: 40px;
  color: #101427;
}

.new-services-list {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.new-service-item {
  display: flex;
  align-items: stretch; 
  gap:30px;
  background:#d3f2fcc1;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.3s;
}

.new-service-item.left .new-image-container {
  order: 1;
}

.new-service-item.right .new-image-container {
  order: 2;
}

.new-service-item.left .new-service-text {
  order: 2;
}

.new-service-item.right .new-service-text {
  order: 1;
}

.new-service-item:hover {
  background: #FFFFED;
  transform: translateY(-5px);
}

.new-image-container {
  flex: 1; 
  text-align: center;
}

.new-image-container img {
  width: 100%;
  height: 100%; 
  object-fit: cover;
}

.new-service-text {
  flex: 1; 
  display: flex;
  flex-direction: column;
  justify-content: center; 
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left:30px;
  padding-right: 30px;
}

.new-service-text h3 {
  font-family: 'Oswald', sans-serif;
  color: #101427;
  font-size: 1.75rem;
  margin-bottom: 10px;
}

.new-service-text p {
  color:#101427;
  margin-bottom: 15px;
  line-height: 1.7em;
}

.new-service-text a {
  color: #101427;
  text-decoration: none;
  font-weight: 600;
}

.new-service-text a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .new-service-item {
    flex-direction: column;
    text-align: left;
  }
  .new-service-container {
    padding: 0px;
  }

  .new-image-container {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .new-service-item.left .new-image-container,
  .new-service-item.right .new-image-container {
    order: 1;
  }
  .new-service-text{
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left:15px;
    padding-right: 15px;
  }

  .new-service-item.left .new-service-text,
  .new-service-item.right .new-service-text {
    order: 2;
  }
}
